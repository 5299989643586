import AboutSection from "../../components/Sections/About";
import Education from "../../components/Sections/Education";
import IntroSection from "../../components/Sections/Intro";
import JoinSection from "../../components/Sections/Join";
import MintSection from "../../components/Sections/Mint";
import PoppySection from "../../components/Sections/Poppy";
import RoadmapSection from "../../components/Sections/Roadmap";
import TeamSection from "../../components/Sections/Team";

const Home = ({isMobile}) => {

  return (
    <>
      <IntroSection isMobile={isMobile} />
      <AboutSection isMobile={isMobile} />
      <PoppySection isMobile={isMobile} />
      <RoadmapSection isMobile={isMobile} />
      <MintSection isMobile={isMobile} />
      <TeamSection isMobile={isMobile} />
      <Education />
      <JoinSection isMobile={isMobile} />
    </>
  );
};

export default Home;
