import { education } from "../../../constants";
import styles from "./index.module.scss";

const Education = () => {
  return (
    <section className={styles["education"]} id="education">
      <div className="container">
        <h2 className={styles["education__title"]}>Education Tab</h2>
        <div className={styles["education__wrap"]}>
          {education.map((item) => (
            <div key={item.id} id={item.id} className={styles["education__card"]}>
              <a target="_blank" rel="noreferrer" href={item.link}>
                <img
                  className={styles["education__card__img"]}
                  src={item.img}
                  alt={item.title}
                />
                <div className={styles["education__card-info"]}>
                  <h3 className={styles["education__card-title"]}>
                    {item.title}
                  </h3>
                  <p className={styles["education__card-desc"]}>{item.desc}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
        <a
          className={styles["education__link"]}
          href="https://nettyart.io/category/nft-education/"
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </section>
  );
};

export default Education;
