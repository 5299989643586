import {
  arlo,
  henry,
  june,
  popyesLines,
  popyesLinesMobile,
  puppiesWitcher,
  stewart,
  zebra,
} from "../../../assets/images";
import styles from "./index.module.scss";

const PoppySection = ({ isMobile }) => {
  return (
    <section className={styles["poppy"]} id="poppy_and_pack">
      <div className="relative">
        <div className="container">
          <h2 className={styles["poppy__title"]}>About Poppy & Pack</h2>
          <div className={styles["poppy__wrap-intro"]}>
            {!isMobile && (
              <div className={styles["poppy__wrap-intro__img"]}>
                <img src={puppiesWitcher} alt="Witcher" />
              </div>
            )}
            <div className={styles["poppy__wrap-intro__block"]}>
              <h3 className={`h2 ${styles["poppy__wrap-intro__title"]}`}>
                Poppy
              </h3>
              {isMobile && (
                <div className={styles["poppy__wrap-intro__img"]}>
                  <img src={puppiesWitcher} alt="Witcher" />
                </div>
              )}
              <div className={styles["poppy__wrap-intro__text"]}>
                <p>
                  By now you know we are engulfed in a magic world about a
                  powerful Witch. But who is she? Where does she come from? This
                  is the place where we tell you all about Poppy.{" "}
                </p>
                <p className="mb-0">
                  Poppy is a Witch, but she’s not just any ol’ human witch. Just
                  like any good witch’s potion or brew, she has 3 parts.
                </p>
                <ul className="list-num">
                  <li>Witch</li>
                  <li>Clear Quartz & dirt from the Joshua Tree Forest</li>
                  <li>Chameleon </li>
                </ul>
                <p>
                  What does this mean? It means, her body isn’t human... she’s
                  dirt, crystals from the earth, chameleon and once the universe
                  swirled and whirled her into existence that magic made her a
                  witch.
                </p>
                <p>
                  Her magic superpowers are interesting in just that way. She
                  can move by running or walking like any mortal, or she can
                  swirl into dust and move quickly to far out locations along
                  with the wind.
                </p>
                <p>
                  What us mortal’s would look at and see skin, isn’t that at all
                  for her. As that’s where the chameleon comes in. She never
                  changes who she is, but she naturally adapts to her
                  surroundings. When the sky is blue, she’s blue. When she’s
                  leaning against a tree she’s the beauty that is the color of
                  the bark, and when the sun set’s she radiates a full spectrum
                  of red, orange and golden yellow. Normally her eyesight
                  requires glasses, but once she stores up enough power she can
                  use Eagle Eyes. I’ve also heard she has a very luscious aura
                  and can feel others.{" "}
                </p>
                <p>
                  {" "}
                  Since we don’t know how Poppy came into existence, we don’t
                  know who her parents are. But here’s a few conclusions we’ve
                  come too.
                </p>
                <ul className="list-num">
                  <li>
                    Her mother is probably a mixture of Catherine O’Hara &
                    Mother Gaia{" "}
                  </li>
                  <li>
                    Her father might be a splash of Danny McBride with Father
                    Earth
                  </li>
                  <li>Maybe she’s a creature from another galaxy?</li>
                  <li>
                    Or maybe she doesn’t have any parents, and she’s just the
                    universe’s magic embodied on planet earth{" "}
                  </li>
                </ul>
                <p>
                  Either way we know she loves deeply, fights passionately, says
                  and thinks the most insane things and is trying her best to
                  understand humans, because animals are really her best
                  friends.
                </p>
                <p>
                  - Speaking of the animals and critters lets introduce this
                  Wild Wolf Pack.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["poppy__lines"]}>
          <img
            src={isMobile ? popyesLinesMobile : popyesLines}
            alt="Popyes lines"
          />
        </div>
      </div>
      <div className="container">
        <div className={styles["poppy__june-wrap"]}>
          <div className={styles["poppy__june-block"]}>
            <h2>June</h2>
            {isMobile && (
              <div className={styles["poppy__june-img"]}>
                <img src={june} alt="June" />
              </div>
            )}
            <p>
              une is our Alpha female. Wolves have been around since the
              beginning of earth time, and they learned quickly how best to
              operate... put the smartest and bravest female in charge. There’s
              a lot really that the mortal’s could learn from Wolf Packs if they
              weren’t so busy murdering them to hang on their homes wall in
              their copy cat versions of “Man Caves”, but we will get to that
              problem later on. For now lets focus on our sly, smart and loud
              June.
            </p>
            <p>
              June is the definition of fierce. Her lovers are Coyotes, she’s
              not afraid of anyone or anything. She takes a significant amount
              of time to trust, and protects her pack with everything she has.
              She’s loud when saying hello, and loud when protecting.. and if
              you haven’t earned her trust yet you won’t know the difference
              between the two howls. She’s tiny but don’t let that fool you.
              She’s cornered Mojave Green Rattlesnakes into submission, she’s
              scared off Aliens, and even scares the shit out of the local
              repair man. She never rests, not till everyone and everything she
              loves is safe, and then, even then, she rests with one eye open.
            </p>
            <p>
              But the biggest miss understanding about June is that she’s mean,
              aggressive and dangerous. In reality that’s not even the slightest
              bit true. She’s gentle, curious, sweet, loves love, and is just
              misunderstood by mortals judgments. If you gain the trust of
              June... then you’ve gain the best trust of all. Unconditional
              Love.
            </p>
          </div>
          {!isMobile && (
            <div className={styles["poppy__june-img"]}>
              <img src={june} alt="June" />
            </div>
          )}
        </div>
        <div className={styles["poppy__stewart-wrap"]}>
          {!isMobile && (
            <div className={styles["poppy__stewart-img"]}>
              <img src={stewart} alt="Stewart" />
            </div>
          )}
          <div className={styles["poppy__stewart-block"]}>
            <h2>Stewart</h2>
            {isMobile && (
              <div className={styles["poppy__stewart-img"]}>
                <img src={stewart} alt="Stewart" />
              </div>
            )}
            <p>
              Stewart is Poppy’s Black Cat, and he never takes off his tuxedo.
              He’s been with her since she can remember. Were they born into
              existence together? Maybe. Stewart is older then time itself. He’s
              loud. His meow sounds like a whale crying out for his mate.
              Stewart loves men, loves a good snaggle tooth kiss, Tuna fresh on
              the daily, a nice fresh poop followed by a sprint. He Basks in the
              sun even more then Poppy.. and the biggest surprise of all is that
              Stewart is the only one who June is afraid of. That relationship
              is the physical definition of respecting personal space and
              boundaries.
            </p>
          </div>
        </div>
        <div className={styles["poppy__henry-wrap"]}>
          <div className={styles["poppy__henry-block"]}>
            <div className={styles["poppy__henry-disc"]} />
            <div className={styles["poppy__henry-content"]}>
              <h2>Henry</h2>
              {isMobile && (
                <div className={styles["poppy__henry-img"]}>
                  <img src={henry} alt="Henry" />
                </div>
              )}
              <p>
                Henry is next in line behind June in age, but not in smarts. For
                smarts he’s the bottom of the Wolf Pack ladder. You’ll never
                meet a Wolf more sweet, affectionate and an instant lover with
                zero degree of trust gaining as you’ll find in Henry. In fact it
                is dangerous. He loves everyone, everything and his life goal is
                spreading love. With love being his Achilles heal, he’s also
                shockingly the best hunter. In fact his hunting skills are so
                good that Poppy is trying to teach him that our friends are
                friends.. and not food.
              </p>
            </div>
          </div>
          {!isMobile && (
            <div className={styles["poppy__henry-img"]}>
              <img src={henry} alt="Henry" />
            </div>
          )}
        </div>
        <div className={styles["poppy__arlo-wrap"]}>
          {!isMobile && (
            <div className={styles["poppy__arlo-img"]}>
              <img src={arlo} alt="Arlo" />
            </div>
          )}
          <div className={styles["poppy__arlo-block"]}>
            <div className={styles["poppy__arlo-disc"]} />
            <h2>Arlo</h2>
            {isMobile && (
              <div className={styles["poppy__arlo-img"]}>
                <img src={arlo} alt="Arlo" />
              </div>
            )}
            <p>
              Oh boy. Where to start with Arlo. First off he’s the baby. He’s
              the newest pack member and still considered a Wolf Pup. Though
              he’s the biggest of all the wolves in size, but he’s still such a
              baby in so many ways. Half the time his voice sounds like a
              squealing pubescent child and other times he is so majestic and
              puts you at awe that you’ll stop in your tracks just to listen. He
              want’s to be most like his big sister, but that’s also who he’s
              most afraid of, other then being left alone. Arlo will never leave
              his mom’s side. In fact we can all joke that Poppy’s shadow side
              is Arlo. It’s the baby in him, or the separation anxiety, we
              aren’t really sure only time will tell. Arlo’s still learning the
              world, so everything to him is still fresh, shinny and new. He
              loves every adventure, looks at you with those big brown eyes and
              you are instantly softened. Some say he looks a little bit like a
              polar bear and a seal had a baby. Arlo’s caveat.. ? he’s bad with
              boundaries. He has zero respect for personal space even to a
              stranger and insists that everyone be greeted by a highly
              inappropriate butt sniff, fine with Wolves, not okay with the
              mortals. He’s in training.. we swear.
            </p>
          </div>
        </div>
        <div className={styles["poppy__zebra-wrap"]}>
          <div className={styles["poppy__zebra-block"]}>
            <h2>Zebra</h2>
            {isMobile && (
              <div className={styles["poppy__zebra-img"]}>
                <img src={zebra} alt="Zebra" />
              </div>
            )}
            <p>
              Now Zebra might seem like the strangest addition to this Mojave
              desert Wild Wolf Pack. But really Zebra was always with Poppy, she
              just didn’t know about her until about 7 years ago. Zebra is the
              spirit of Poppy’s soul. Zebra is called upon for Poppy when she
              needs strength. Zebra is Poppy’s power, her gentle giant, and her
              powerhouse. Zebra isn’t Poppy’s spirit animal... Zebra is Poppy’s
              Power. She rides on her back with the greatest of ease and the two
              are one. Is Poppy really Zebra? Or is Zebra really Poppy?
            </p>
            <p>
              Now as we move through our stories you might ask who I am? Who is
              this walking you through this world? Am I God? Am I the sun? Maybe
              the Moon? Or am I just you?
            </p>
          </div>
          {!isMobile && (
            <div className={styles["poppy__zebra-img"]}>
              <img src={zebra} alt="Zebra" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PoppySection;
