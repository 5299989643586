import {
  guideImg,
  metamaskImg,
  mintNft,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
} from "./assets/images";

export const PATHS = {
  home: "/",
  mint: "/mint",
};

export const education = [
  {
    id: 1,
    img: metamaskImg,
    title: "How To Create a MetaMask Wallet",
    desc: "Learn how to create a Metamask NFT wallet. We cover the steps needed and whats required to get started in your NFT journey.",
    link: "https://nettyart.io/a-guide-to-setting-up-a-metamask-wallet/",
  },
  {
    id: 2,
    img: guideImg,
    title: "The Beginner’s Guide To NFTs",
    desc: "Cryptocurrency has expanded significantly during the past few years, and many people are wondering what are NFTs.",
    link: "https://nettyart.io/the-beginners-guide-to-nfts/",
  },
  {
    id: 3,
    img: mintNft,
    title: "How To Mint NFTs",
    desc: "If you are looking for a way to mint NFT directly on the project’s website, there are several steps to follow.",
    link: "https://nettyart.io/step-by-step-how-to-mint-nfts/",
  },
];

export const menu = [
  "Home",
  "About",
  "Poppy and Pack",
  "Roadmap",
  "Booklet",
  "Team",
  "Teaser",
  "Community",
  "Sparkle Paper",
];

export const team = [
  {
    id: 1,
    img: team1,
    name: "Maranda",
    position: "Founder & Designer",
    text: `
    <p>
    Maranda is the Creator, Designer and Founder of Wild Wolf Witch. A Costume Designer by trade for Film and TV, and artist and storyteller at heart. She’s constantly working with in new mediums of creativity from NFT’s & Web3, Costume Design, Architecture and Interior Design with a through line goal of helping others through creativity, raw beauty and authenticity. A Mystic who’s happiest in nature, constantly finding a deeper understanding of the Universe and its Magic. Maranda loves being at home in California with her husband Rob, Her cat Stewart, and her 3 rescue Huskies June, Henry and Arlo. Hopefully one day a Zebra, Horse, Goats and Chickens. 
    </p>
    <p>
    A lot of people ask if Poppy is Maranda or is Poppy based on Maranda? And my answer is both no and yes all at the same time. There’s so much of M in Poppy, but so much of Poppy that’s not in M. Which then I ask…Don’t we all have our unique inner Poppy version of us? Which one will you bring into the world today? 
    </p>
    `,
  },
  {
    id: 2,
    img: team2,
    name: "Tuckie & Mackenzie",
    position: "Writers",
    text: `
    <p>
    BFF’s & Dynamic Duo Writers! Who puts their Bio’s and Photo together?! These two! 
    Tuckie White is an actor, writer, and voice over artist. She lives in Chicago and has loads of regrets. Philip Seymour Hoffman once told her she would "surely do great things." Mackenzie and Tuckie are BFF’s. Maranda met Mackenzie while she was costume designing her YouTube Premium show “Overthinking with Kat & June”, where a friendship between the two was forged in the fires of artistic collaboration. Mackenzie is a television writer who’s written for such shows as “Girl Meets World,” “Bizaardvark” and Hulu’s “All Night”. She spends her time foraging and making potions in her apothecary (garage). 
    </p>
    `,
  },
  {
    id: 3,
    img: team3,
    name: "Lauren",
    position: "Producer",
    text: `
    <p>
    Lauren is a creative producer and storyteller currently based in Los Angeles, CA. A proud multi-cultural creative born and raised in the Midwest, Lauren's creative interests are as diverse as her heritage. While she has produced a variety of different projects, she personally gravitates toward darker narratives that blend contrasting tones like comedy and tragedy to spotlight a unique intersection of the human experience.
    </p>
    <p>
    She has worked in the industry for the past 9 years producing for various companies such as BuzzFeed, Amazon, Apple and Facebook specializing in digital scripted and unscripted production. Her latest BuzzFeed series, Cocoa Butter's The Era, can be seen streaming on Hulu. She currently works at TikTok as a Production Ops Specialist on the Editorial Programming team.  </p>
    </p>
    `,
  },
  {
    id: 4,
    img: team4,
    name: "Nicole",
    position: "Director of Photography ",
    text: `
    <p>
    Nicole Doiron is a Photographer, Director, and Cinematographer from Laguna Beach, California. She attended Chapman University's Dodge College of Film and Media Arts and is currently a freelance Cinematographer and Associate Producer at Rosewood Creative. Nicole has a passion for telling unique stories and experimenting with different types of media.   
    </p>
    `,
  },
  {
    id: 5,
    img: team5,
    name: "July Grullon",
    position: "CEO NettyArt",
    text: `
    <p>
    July has been involved in multiple start-ups and businesses from luxury goods, motorsports & the payment industry. He has over a decade of experience in digital marketing & e-commerce   
    </p>
    `,
  },
  {
    id: 6,
    img: team6,
    name: "NettyArt",
    position: "Marketing",
    text: `
    <p>
    Is an NFT platform that provides the latest NFT News, Sales, Rarity, and data to help collectors make better decisions.   </p>
    `,
  },
  {
    id: 7,
    img: team7,
    name: "Gsd Club",
    position: "Art & Development",
    text: `
    <p>
    Gsd Club is covering art and development of programmatically generated NFT collections. The team is formed of 2D, 3D modelers, animators and blockchain developers. The main focus of our company is bringing new possibilities and features to NFT world.
    </p>   `,
  },
];

export const thanks = [
  {
    id: 1,
    name: "Rob",
    text: "Thank you Rob for reminding me I’m a bad ass witch when times get tough. Your belief in my crazy idea’s is never taken for granted. Also Thank you for walking the kids early in the morning when its too cold out for me. Love you. ",
  },
  {
    id: 2,
    name: "Matt Birch",
    position: "President & CEO Mockingbird Media",
    text: "Thank you Matt for being the best friend and agent in the entire world.",
  },
  {
    id: 3,
    name: "Dani Rose",
    text: "Dani Rose is the lead singer of Honey County. Thank you Dani for your friendship and ability to sit with me for hours to edit. ",
  },
  {
    id: 4,
    name: "Alexia Dox",
    text: "Thank you Alexia for your hilarious take on Poppy, for your friendship, and mad green screen skills.",
  },
  {
    id: 5,
    name: "Adi Jaffe",
    text: "Thank you Adi for being a wonderful friend with an amazing skillset of music production and DJ-ing.",
  },
];
