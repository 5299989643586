import { footerLogo } from "../../assets/images";
import styles from "./index.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <footer className={styles["footer"]}>
      <div className="container">
        <a href="#home">
          <img className={styles["footer__logo"]} src={footerLogo} alt="Logo" />
        </a>
        <h2>Bask in the Sun, Howl at the Moon</h2>
        <p>© {date.getFullYear()} Wild Wolf Witch. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
