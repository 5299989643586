import { characters, introText, introTextMobile } from "../../../assets/images";
import styles from "./index.module.scss";

const IntroSection = ({ isMobile }) => {
  return (
    <section className={styles["intro"]} id="home">
      <div className="container">
        <div className={styles["intro__text"]}>
          <img src={isMobile ? introTextMobile : introText} alt="Logo" />
        </div>
      </div>
      <img
        className={styles["intro__characters"]}
        src={characters}
        alt="Characters"
      />
    </section>
  );
};

export default IntroSection;
