import {
  eagle,
  Ellipse,
  futureBottom,
  mapImg,
  owl,
} from "../../../assets/images";

import styles from "./index.module.scss";

const RoadmapSection = ({ isMobile }) => {
  return (
    <section className={styles["roadmap"]} id="roadmap">
      <img
        className={styles["roadmap__bg-bottom"]}
        src={futureBottom}
        alt="Fungi"
      />
      <div className={styles['roadmap__first']}>
        <div className="container p-md-0">
          <div className={styles["roadmap__mobile"]}>
            <h2 className="text-center">Roadmap</h2>
            <a className={styles["roadmap__link"]} href="/map.png" download>
              Click here to download the roadmap
            </a>
          </div>
          <img
            className={styles["roadmap__map-img"]}
            src={mapImg}
            alt="Roadmap"
          />
        </div>
        <div className="container p-md-0">
          <div className={styles["roadmap__first-info"]}>
            <div>
              <div>
                <h2>Past</h2>
                <p>
                  We planted a seed during a New Moon and worked our asses off.
                  We will teach you the ways of manifestation during a New Moon
                </p>
              </div>
            </div>
            <div>
              <h2>Present: Mint Sptember 2022</h2>
              <p>
                We are sending you our special “Sparkle dust Gifted NFT”, a
                little gem from us to you, our community to kick off the
                adventure of a lifetime
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-md-0">
        <div style={{ position: "relative" }}>
          <div className={styles["future__bg_black"]} />
          <div className={styles["future"]}>
            <img className={styles["future__owl"]} src={owl} alt="Owl" />
            <img className={styles["future__eagle"]} src={eagle} alt="Eagle" />
            <div className={styles["future__bg"]} />
            <div className={styles["future__block"]}>
              <div className={styles["future__block-first-part"]}>
                <Ellipse
                  className={styles["future__block-first-part_ellipse"]}
                />
                <h2 className={styles["future__block-first-part__first-title"]}>
                  Future
                </h2>
                <h2
                  className={styles["future__block-first-part__second-title"]}
                >
                  NFT Collection
                </h2>
                <p>
                  <u>launching from the sun and moon </u>
                </p>
                <h2 className={styles["future__block-first-part__third-title"]}>
                  Halloween 2022
                </h2>
                <p>
                  <u>BOO WITCHES</u>
                </p>
              </div>
              <div className={styles["future__block-second-part"]}>
                <h2>Future Future</h2>
                <h3>Wild Wolf Witch Costume Lottery!</h3>
                <p>
                  NFT holders will be automatically entered to win a Movie
                  quality custom handmade Costume directly from their NFT.
                  Winners will be able to give their personal chosen sizes and
                  Maranda will design and make your costume from scratch!
                </p>
                <h3>Wild Wolf Witch Booklet!</h3>
                <p>
                  A collection of 7 short stories straight from Poppy & her Wild
                  Pack.{" "}
                </p>
                <p> - Exclusive 1,111 Free Mint of our 1st Story Booklet</p>
                <p> - Exclusive 2,222 Mint of our 2nd Story Booklet</p>
                <p> - Exclusive 3,333 Mint of our 3rd Story Booklet</p>
                <p> - Stories 4-7 will have special surprises!</p>
                <p> - Holders will receive IRL Booklets! </p>

                <h3>Wild Wolf Witch Teaser!</h3>
                <p>Get a little taste of the magic we are brewing</p>

                <h3>Wild Wolf Witch Merchy-Merch</h3>
                <p>
                  Because our Founder is a designer expect an out of this world
                  collection of Merch! All custom designed from M!
                </p>

                <p>
                  <span className="font-retro">Wild Wolf Witch TV Show.</span>{" "}
                  Yes, we are manifesting a TV show. We are pitching to
                  different studio’s & Film3 networks to see where is the best
                  fit for Poppy’s Den.
                </p>
                <p>
                  NFT holders will be included in the TV show building process,
                  we want our community to help us build what we all want to
                  watch!
                </p>
                <p>
                  <span className="font-retro">Giveaway!</span> Wild Wolf Witch
                  is giving away a WoW NFT! Get entered to win by following
                  @wildwolfwitch_ on Twitter, Minting our Gifted Story, and join
                  our Wolf Pack!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapSection;
