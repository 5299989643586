import { mintBg } from "../../../assets/images";
import styles from "./index.module.scss";

const MintSection = ({ isMobile }) => {
  return (
    <section id="booklet" className={styles["mint"]}>
      <div className="container">
        <div className={styles["mint__wrap"]}>
          <div>
            <h2 className={styles["mint__title"]}>
              “The Adventures of Poppy & Wild Wolf Pack ”
            </h2>
            <button className={styles["mint__btn"]}>Mint Booklet</button>
          </div>
          <img src={mintBg} alt="Mint" />
        </div>
      </div>
    </section>
  );
};

export default MintSection;
