import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { menu, PATHS } from "../../constants";
import styles from "./index.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();

  const handleSticky = () => {
    if (window.pageYOffset > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.pageYOffset > 100 && setIsSticky(true);
    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  const handleToogleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <header
      className={cn(styles.header, {
        [styles.header_fixed]: isSticky || isOpen,
      })}
    >
      <div className="container">
        <div className={styles["header__wrap"]}>
          <div>
            <Link
              className={cn(styles.header__logo, {
                [styles.header__logo_mint]: pathname === PATHS.mint,
              })}
              to={PATHS.mint}
            >
              Mint
            </Link>
          </div>
          <div>
            <nav>
              <button onClick={handleToogleMenu} className={styles.burger__btn}>
                <div
                  className={cn(styles.burger__icon, {
                    [styles.burger__icon_open]: isOpen,
                  })}
                />
              </button>
              <ul
                className={cn(styles.menu, {
                  [styles.menu__open]: isOpen,
                })}
              >
                {menu.map((item, idx) => (
                  <li key={idx}>
                    <a
                      onClick={() => setIsOpen(false)}
                      className={styles["menu__link"]}
                      href={`/#${item.toLowerCase().replace(/\s/g, "_")}`}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
