import { clockVideo } from "../../assets";
import { mintWitch } from "../../assets/images";
import Mint from "../../components/Mint";
import styles from "./mint.module.scss";

const MintPage = () => {

  return (
    <div className={styles["mint"]}>
      <div className="container">
        <div className={styles["mint__wrap"]}>
          <img src={mintWitch} alt="Mint witch" />
          <Mint />
          <div>
            <h2>Countdown</h2>
            <div>
              <video className={styles["mint__video"]} autoPlay muted loop>
                <source src={clockVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintPage;
