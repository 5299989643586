import { useState } from "react";
import styles from "./index.module.scss";

const Mint = () => {
  const [count, setCount] = useState(1);

  const handleChangePlus = () => {
    setCount((prev) => +prev + 1);
  };

  const handleChangeMinus = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
    }
  };

  return (
    <div className={styles["mint__block"]}>
      <h2>Price</h2>
      <p>0.15 ETH Per NFT</p>
      <h2>Limits</h2>
      <p>10 Per Transaction</p>
      <div className={styles["input__wrap"]}>
        <button onClick={handleChangeMinus} className={styles["input__btn"]}>
          -
        </button>
        <input
          readOnly
          onChange={({ target }) => setCount(target.value)}
          className={styles["input"]}
          value={count}
          type="text"
        />
        <button onClick={handleChangePlus} className={styles["input__btn"]}>
          +
        </button>
      </div>
      <button className={styles["mint__btn"]}>Contact Wallet</button>
    </div>
  );
};

export default Mint;
