import { Instagram, Tiktok, Twitter } from "../../../assets/images";
import styles from "./index.module.scss";

const JoinSection = ({ isMobile }) => {
  return (
    <section className={styles["join"]} id="community">
    <h2 className={styles["join__title"]}>
      Join Our
      <br /> Community
    </h2>
    <div className={styles["join-social"]}>
      <a href="https://www.tiktok.com/@wildwolfwitchnft" target="_blank" rel="noopener noreferrer">
        <Tiktok /> Join our TikTok
      </a>
      <a href="https://twitter.com/wildwolfwitch_" target="_blank" rel="noopener noreferrer">
        <Twitter /> Follow us on Twitter
      </a>
      <a href="https://www.instagram.com/wildwolfwitchnft/" target="_blank" rel="noopener noreferrer">
        <Instagram /> Follow us on IG
      </a>
    </div>
    <div className={styles["join__block"]}>
      <div className={styles["join__block-text"]} id="sparkle_paper">
        <h2 className={styles["join__block-title"]}>Sparkle Paper!</h2>
        <p>
          Our NFT Collections will continue to travel around the world with
          Poppy! Each collection will travel to a new location in the world
          and we will meet all of Poppy’s new Magic friends from around the
          world.
        </p>
        <p>
          Our first collection: Wild Wolf Witch Joshua Tree, will donate a
          portion of profits to help saves wolves in North America from
          extinction. #relistwolves.
        </p>
        <p>
          Wild Wolf Witch will donate a portion of profits to different non
          profit organizations dedicated to preserving and protecting the
          fragile Joshua Tree forest eco system.
        </p>
        <p>
          As we travel to new locations around the world with Poppy and her
          friends, each collection will donate a portion of profits to help
          protect Wildlife in those locations.
        </p>
        <p>
          Wild Wolf Witch x Wild Wolf Ranch. Curating special events and
          mental wellness days. Wild Wolf Ranch is an eclectic desert
          hacienda in Joshua Tree CA working towards creating spaces for
          healing for all pack members.
        </p>

        <p>
          Wild Wolf Witch x Costume Design & Styling & Interior Design w/ M
          Our Wolf Pack can join Live Gathering with our Founder Maranda:{" "}
        </p>
        <p>- Learn how to break down a script</p>
        <p>- How to design a character</p>
        <p>- What looks best of TV vs. Movies vs. Live Stage Shows</p>
        <p>- Sewing Basics</p>
        <p>- Draping and Pattern Making Basics</p>
        <p>- Interior Design</p>
        <p>- Thrifting</p>
        <p>
          Wild Wolf Witch Sound Baths: Join Poppy in Spaces twice a month to
          sooth your nervous system in a live soundbath
        </p>
      </div>
    </div>
  </section>
  );
};

export default JoinSection;
