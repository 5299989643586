import { girlMobile } from "../../../assets/images";
import styles from "./index.module.scss";

const AboutSection = ({ isMobile }) => {
  return (
    <section className={styles["about"]} id="about">
    <div className="container">
      <div className={styles["about__block"]}>
        <h2 className={styles["about__title"]}>About Wild Wolf Witch</h2>
        <p>Somewhere, deep in the Joshua Tree forest,</p>
        <p>lives a powerful witch and her Wild Pack.</p>
        <ul className="list">
          <li>3 Wolves </li>
          <li>a Black Cat with a tuxedo</li>
          <li> and a Zebra.</li>
        </ul>
        <p>
          <u>They are our past, our present but maybe our future.</u>
        </p>
        <p>
          This is the tale of no beginning, and no end, there is just today.
          It is said that the laughs from deep in their belly’s can be heard
          all the way to Pioneer Town, dare we even say Palm Springs.
        </p>
        <p>
          Their mission is simple...to protect those who can’t protect
          themselves, and live in peace amongst the trees and wildlife. If
          only life were truly that simple.
        </p>
        <p>
          This isn’t just any ordinary pack to keep your eye on. They are
          what some might call ridiculous, some might say crazy, and some
          might say they are just living life to the fullest degree of
          expression.
        </p>
        <p>
          Are you ready to connect to your highest, truest and most magical
          self? Yes or No... buckle up because this adventure is about to
          get howling!
        </p>
        <p>
          <u>Wild Wolf Witch is a narrative world. </u> It’s a world full of
          magic where no one has to hide who they truly are. They just are.
          Inspired by Joshua Tree, California; We follow Poppy a Powerful
          Witch and her Wild Wolf Pack. A world where love mixed with fairy
          dust magic is the most powerful tool in our toolbox, All people
          and wildlife are celebrated and honored for who they are. A 1970’s
          aesthetic without the inequalities amongst mortals. This isn’t
          drama, this isn’t serious. This is hilarious, fun, brilliant
          sparkle that will make you cry from laughing and maybe just maybe
          save a few wolves and Joshua Tree’s trees along the way.
        </p>
      </div>
    </div>
    {isMobile && (
      <img
        className={styles["about__girl-img"]}
        src={girlMobile}
        alt="Girl"
      />
    )} 
  </section>
  );
};

export default AboutSection;
