import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { PATHS } from "./constants";
import Layout from "./layout";
import Home from "./pages/Home";
import MintPage from "./pages/Mint";
import "./styles/index.scss";

function App() {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(window.innerWidth < 992)
  }, [])
  
  return (
    <Router>
      <Routes>
        <Route
          path={PATHS.home}
          element={
            <Layout>
              <Home isMobile={isMobile} />
            </Layout>
          }
        />
        <Route
          path={PATHS.mint}
          element={
            <Layout footer={false}>
              <MintPage />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
