import Footer from "../components/Footer";
import Header from "../components/Header";

const Layout = ({ children, footer = true }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      {footer && <Footer />}
    </>
  );
};

export default Layout;
