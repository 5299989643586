import {
  ellipseTeam
} from "../../../assets/images";
import { team, thanks } from "../../../constants";
import styles from "./index.module.scss";

const TeamSection = ({ isMobile }) => {
  return (
    <section className={styles["team"]} id="team">
      <div className={styles["team__bg"]}>
        <div className="container">
          <h2 className={styles["team__title"]}>Our Team Members</h2>
          <div className={styles["team__wrap"]}>
            {team.map((item) => (
              <div key={item.id} className={styles["team__item"]}>
                {item.id === 1 && (
                  <img
                    className={styles["team__ellipsis"]}
                    src={ellipseTeam}
                    alt="Ellipse"
                  />
                )}
                <div className="relative">
                  <img
                    className={styles["team__block-img"]}
                    src={item.img}
                    alt={item.name}
                  />
                  <h3 className={styles["team__block-title"]}>{item.name}</h3>
                  <p className={styles["team__block-position"]}>
                    {item.position}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles["thanks"]} id="teaser">
        <div className="container p-md-0">
          <h2 className={styles["thanks__title"]}>
            Special Thank you’s from Maranda
          </h2>
          <div className={styles["thanks__wrap"]}>
            {thanks.map((item) => (
              <div key={item.id}>
                <div>
                  <h3>{item.name}</h3>
                  {item.position && <p>{item.position}</p>}
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
